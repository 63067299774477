import React, { useContext } from 'react';
import Form from "../Contact/Form";
import { GlobalDataContext } from '../../context/context';

function FormHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (

    <>
      <div className='w-full px-20 flex justify-center'>
        <div className='w-[40%] p-5 mx-5 bg-white shadow-lg '>
            <h2 className='text-center'>Send Us A Message</h2>
            <div className='p-4'>
            <Form/>
            </div>
        </div>
        <div className=' w-[60%] p-6'>
            <img
                src={rpdata?.dbPrincipal?.logo}
                alt='logo'
                loading='lazy'
                className='w-[80%] mx-auto pt-3 pb-5'
              />
        </div>
      </div>
    </>
  )
}

export default FormHome
